import api from "@/plugins/api/EntryPoint";
import axios from "axios";

export default {
  /**
   * Retrieve accounst from server before return account prepare them for displaying
   * few resions to prepare data - right variable names, format words
   * @param callBackFn
   * @returns {Promise<*>}
   */
  getAccounts(callBackFn) {
    return api.get("settings/get-accountsapp", (response) => {
      let accountsData = {};
      for (let i = 0; i < response.accounts.length; i++) {
        let account = response.accounts[i];

        accountsData[account.ls] = this.prepareAccount(account);
      }

      callBackFn(accountsData);
    });
  },
  async importAccounts(callBackFn) {
    return api.get("ls/import-old", callBackFn);
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  prepareAccount(account) {
    return {
      accountNumber: account.ls,
      city: account.city
          ? this.capitalizeFirstLetter(account.city.toLowerCase())
          : "",
      streetType: account.street_typ,
      building: account.building,
      streetName: account.street_nam
          ? this.capitalizeFirstLetter(account.street_nam.toLowerCase())
          : "",
      appartment: account.apartment === "" ? null : account.apartment,
      balance: account.debt ? account.debt : 0,
      accountVerified: account.check_obl,
      ls: account.ls,
      tariff: account.tariff ? account.tariff : 0,
    };
  },
  getAccount(accountNumber, callBackFn) {
    return api.get(`ls/full-search?ls=${accountNumber}`, (response) => {
      let accountData = this.prepareAccount(response.balance);
      callBackFn(accountData);
    });
  },
  async refreshToken(cb) {
    return await api.get(`auth/refresh-token`, (response) => {
      return cb(response);
    });
  },
  storeAccount(accountNumber, cb) {
    return api.post("ls/add-account", { ls: accountNumber }, (response) => {
      return cb(response);
    });
  },
  async nativeAuth(nativeData) {
    console.log("CHLENIX FOR BACK: " + JSON.stringify(nativeData));
    return await axios
        .post(
            process.env.VUE_APP_API_ENDPOINT_URL + "userAuth",
            nativeData
        )
        .catch((error) => {
          console.log(error);
        });
  },
  googleAuth(nativeData) {
    console.log("CHLENIX FOR BACK googleAuth: " + JSON.stringify(nativeData));
    return axios
        .post(process.env.VUE_APP_API_ENDPOINT_URL + "googleAuth", nativeData)
        .catch((error) => {
          console.log(error);
        });
  },
  deleteAccountByNumber(accountNumber, callBackFn) {
    return api.post(
        "settings/delete-account",
        { ls: accountNumber },
        callBackFn
    );
  },
  deleteUser() {
    return api.post(
        "settings/delete-user",
    );
  },
  guestMode(guest, cb) {
    return api.put("settings/set-guest", { guest: guest }, cb);
  },
  getGuestMode(callBackFn) {
    return api.get("settings/guest", callBackFn);
  },
  getSubscriptionStatus(callBackFn) {
    return api.get("settings/get-user-status", callBackFn)
  }
};
