import payment from "@/plugins/api/factories/Payment";

export const state = {
  paymentResponse: null,
  paymentData: {
    amount: null,
    app_id: process.env.VUE_APP_EASYPAY_APP_ID,
    ls: null,
    gas_ls: null,
    status_id: null,
    save_card: false,
    card_token: null,
    balances: [],
    browserInfo: null,
    userAgent: null,
    checked: false,
    status: 4
  },
  cards: [],
  paymentUrl: null,
  addCardUrl: null,
  addBalanceCardUrl: null,
  order_id: null
};

export const actions = {
  async getPaymentPage({ commit, state }) {
    return await payment.getPaymentUrl(state.paymentData, async (response) => {
      commit("SET_PAYMENT_URL", response.url);
      commit("SET_PAYMENT_RESPONSE", response);
    });
  },
  async getPortmonePaymentPage({ commit, state }) {
    return await payment.getPortmonePaymentUrl(state.paymentData, async (response) => {
      console.log(JSON.stringify(response))
      commit("SET_ORDER_ID", response);
    });
  },
  async getPaymentStatus(store, payment_data) {
    console.log(JSON.stringify(payment_data))
  },
  async getPaymentBalancesPage({ commit, state }) {
    return await payment.getPaymentBalancesUrl(state.paymentData, async (response) => {
      commit("SET_PAYMENT_URL", response.url);
      commit("SET_PAYMENT_RESPONSE", response);
    });
  },
  async getCardsList({ commit }, cardType) {
    await payment.getEasyPayCardsList({card_type: cardType}, (response) => {
      commit("SET_PAYMENT_CARDS", response.cards ? response.cards : []);
    });
  },
  async deleteCard(store, cardToken) {
    return await payment.deleteCard({ card_token: cardToken }, (res) => {
      console.log("DELETE CARD RESPONSE" + JSON.stringify(res));
      return res;
    });
  },
  async deleteBalancesCard(store, cardToken) {
    return await payment.deleteBalancesCard({ card_token: cardToken }, (res) => {
      return res;
    });
  },
  async getSaveCardPage({ commit }) {
    await payment.getEasyPaySaveCardsPage((response) => {

      commit("SET_ADD_CARD_URL", response.url);
    });
  },
  async getSaveBalanceCardPage({ commit }) {
    await payment.getEasyPaySaveBalanceCardsPage((response) => {

      commit("SET_ADD_BALANCE_CARD_URL", response.url);
    });
  },
  async getStripeUrl(store, stripeData) {
    return await payment.getStripe({ stripeData }, (res) => {
      return res;
    })
  },
  async cancelSubscription(store, stripeData) {
    return await payment.cancelSubscription({stripeData}, (res) => {
      return res;
    })
  }
};

export const mutations = {
  SET_PAYMENT_RESPONSE(state, response) {
    state.paymentResponse = response;
  },
  SET_PAYMENT_CARDS(state, cards) {
    state.cards = cards;
  },
  SET_CARD_TOKEN(state, token) {
    state.paymentData.card_token = token;
  },
  SET_SAVE_CARD(state, type) {
    state.paymentData.save_card = type;
  },
  SET_PAYMENT_URL(state, url) {
    state.paymentUrl = url;
  },
  UPDATE_AMOUNT(state, amount) {
    state.paymentData.amount = amount <= 0 ? 10 : amount;
  },
  SET_USER_LS(state, ls) {
    state.paymentData.ls = ls;
  },
  SET_GAS_LS(state, ls) {
    state.paymentData.gas_ls = ls;
  },
  SET_STATUS_ID(state, status_id) {
    state.paymentData.status_id = status_id;
  },
  SET_BALANCES(state, balances) {
    state.paymentData.balances = balances;
  },
  SET_BROWSER_INFO(state, browserInfo) {
    state.paymentData.browserInfo = browserInfo;
  },
  SET_USER_AGENT(state, userAgent) {
    state.paymentData.userAgent = userAgent;
  },
  SET_ADD_CARD_URL(state, url) {
    state.addCardUrl = url;
  },
  SET_ADD_BALANCE_CARD_URL(state, url) {
    state.addBalanceCardUrl = url;
  },
  SET_PAYMENT_CHECKED(state, checked) {
    state.paymentData.checked = checked;
  },
  SET_ORDER_ID(state, order_id) {
    state.order_id = order_id;
  },
  SET_STATUS(state, status) {
    state.paymentData.status = status;
  }
};
