import api from "@/plugins/api/EntryPoint";

export default {
  async getPaymentUrl(params, callBackFn) {
    console.log(JSON.stringify(params))
    return await api.post(`payment/easy-pay/payment`, params, callBackFn);
  },
  async getPortmonePaymentUrl(params, callBackFn) {
    console.log(JSON.stringify(params))
    return await api.post(`payment/portmone/payment`, params, callBackFn);
  },
  async getPaymentBalancesUrl(params, callBackFn) {
    console.log(JSON.stringify(params))
    return await api.post(`payment/easy-pay/payment-balances`, params, callBackFn);
  },
  async getEasyPayCardsList(params, callBackFn) {

    await api.post(`payment/easy-pay/card-list`, params, callBackFn);
  },
  async getEasyPaySaveCardsPage(callBackFn) {
    await api.post(`payment/easy-pay/3d-secure-url-alt`, {}, callBackFn);
  },
  async getEasyPaySaveBalanceCardsPage(callBackFn) {
    await api.post(`payment/easy-pay/3d-secure-url-alt-balance`, {}, callBackFn);
  },
  async deleteCard(params, callBackFn) {
    return await api.post(`payment/easy-pay/delete-card`, params, callBackFn);
  },
  async deleteBalancesCard(params, callBackFn) {
    return await api.post(`payment/easy-pay/delete-balances-card`, params, callBackFn);
  },
  async getStripe(params, callBackFn) {
    return await api.post("stripe/getUrl", params.stripeData, callBackFn)
  },
  async cancelSubscription(params, callBackFn) {
    return await api.post("stripe/cancel-subscription", params.stripeData, callBackFn)
  }

};
