<template>
  <van-row justify="center" class="page-wrapper setting-main-page">
    <van-col span="24" class="itserve-center flex-display-fix">
      <!--      <router-link :to="{ name: 'Security' }">-->
      <!--        <settings-block>-->
      <!--          <template v-slot:icon>-->
      <!--            <check-icon />-->
      <!--          </template>-->
      <!--          <template v-slot:title>{{ $t("settings.security") }}</template>-->
      <!--          <template v-slot:content>-->
      <!--            {{ $t("settings.securitySettings") }}</template-->
      <!--          >-->
      <!--        </settings-block>-->
      <!--      </router-link>-->
      <!--      <router-link :to="{ name: 'AccountsList' }">-->
      <!--        <settings-block>-->
      <!--          <template v-slot:icon>-->
      <!--            <user-icon />-->
      <!--          </template>-->
      <!--          <template v-slot:title> {{ $t("settings.accounts") }} </template>-->
      <!--          <template v-slot:content>-->
      <!--            {{ $t("settings.accountsSettings") }}-->
      <!--          </template>-->
      <!--        </settings-block>-->
      <!--      </router-link>-->
      <router-link :to="{ name: 'StripePayment' }">
        <settings-block>
          <template v-slot:icon>
            <dollar-sign-icon-new />
          </template>
          <template v-slot:title> {{ $t("settings.stripe") }} </template>
          <template v-slot:content>
            {{ $t("settings.stripeSettings") }}</template
          >
        </settings-block>
      </router-link>
      <!--      <router-link :to="{ name: 'PaymentCards' }">-->
      <!--        <settings-block>-->
      <!--          <template v-slot:icon>-->
      <!--            <credit-card-icon />-->
      <!--          </template>-->
      <!--          <template v-slot:title> {{ $t("settings.cards") }} </template>-->
      <!--          <template v-slot:content>-->
      <!--            {{ $t("settings.cardsSettings") }}</template-->
      <!--          >-->
      <!--        </settings-block>-->
      <!--      </router-link>-->
      <router-link :to="{ name: 'DeleteUser' }">
        <settings-block>
          <template v-slot:icon>
            <user-icon />
          </template>
          <template v-slot:title> {{ $t("settings.user") }}</template>
          <template v-slot:content>
            {{ $t("settings.userSettings") }}</template
          >
        </settings-block>
      </router-link>
      <!--            <router-link :to="{ name: 'AutorizationPage' }">-->
      <!--              <settings-block>-->
      <!--                <template v-slot:icon>-->
      <!--                  <developer-board-icon />-->
      <!--                </template>-->
      <!--                <template v-slot:title> {{ $t("settings.autorization") }} </template>-->
      <!--                <template v-slot:content>-->
      <!--                  {{ $t("settings.autorizationSettings") }}</template-->
      <!--                >-->
      <!--              </settings-block>-->
      <!--            </router-link>-->
      <router-link :to="{ name: 'OfertaPage' }">
        <settings-block>
          <template v-slot:icon>
            <receipt-icon />
          </template>
          <template v-slot:title> {{ $t("settings.oferta") }} </template>
        </settings-block>
      </router-link>
      <router-link :to="{ name: 'PolicyPage' }">
        <settings-block>
          <template v-slot:icon>
            <receipt-icon />
          </template>
          <template v-slot:title> {{ $t("settings.policy") }} </template>
        </settings-block>
      </router-link>
      <!--      <settings-block>-->
      <!--        <template v-slot:icon>-->
      <!--          <heart-icon />-->
      <!--        </template>-->
      <!--        <template v-slot:title> {{ $t("settings.help") }} </template>-->
      <!--      </settings-block>-->
      <!--      <template v-if="themeReady">-->
      <!--        <div style="margin: auto" class="theme_checkbox">-->
      <!--          <CheckBoxIOS />-->
      <!--        </div>-->
      <!--      </template>-->
    </van-col>
    <van-col
        span="24"
        class="itserve-center flex-display-fix"
    >
      <template v-if="settings !== null">
        <div class="place-content-center">
          <van-form>
            <itserve-language-picker v-model="language" />
            <!--            <info-message class="apply-loader settings-message">-->
            <!--              {{ $t("settings.toReceiveReceipt") }}-->
            <!--            </info-message>-->
            <!--            <itserve-input-->
            <!--              v-model="name"-->
            <!--              type="text"-->
            <!--              class="apply-loader fl-upper"-->
            <!--              :placeholder="$t('settings.name')"-->
            <!--              name="name"-->
            <!--            />-->
            <!--            <itserve-input-->
            <!--              v-model="surname"-->
            <!--              type="text"-->
            <!--              class="apply-loader fl-upper"-->
            <!--              :placeholder="$t('settings.surname')"-->
            <!--              name="surname"-->
            <!--            />-->
            <itserve-input
                v-model="email"
                type="text"
                class="apply-loader"
                :placeholder="$t('settings.email')"
                name="email"
            />
          </van-form>
          <itserve-button
              type="primary"
              class="itserve-round-btn itserve-button-wide mb-25 apply-loader"
              :value="$t('buttons.saveChanges')"
              @click="changeSettings"
          ></itserve-button>
        </div>
        <simple-dialog
            v-model="showDialog"
            :success="true"
            :title="this.$t('settings.successMessage')"
        />
      </template>
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
import ItserveLanguagePicker from "@/components/forms/ItserveLanguagePicker";
import SettingsBlock from "@/components/content/SettingsBlock";
// import CheckBoxIOS from "@/components/content/CheckBoxIOS";
// import CheckIcon from "vue-feather-icons/icons/CheckIcon";
import UserIcon from "vue-feather-icons/icons/UserIcon";
// import CreditCardIcon from "vue-feather-icons/icons/CreditCardIcon";
import "vue-awesome/icons/save";
import SimpleDialog from "@/components/content/SimpleDialog";
// import InfoMessage from "@/components/content/InfoMessage";
// import DeveloperBoardIcon from "vue-material-design-icons/DeveloperBoard";
import ReceiptIcon from "vue-material-design-icons/Receipt";
import DollarSignIconNew from "@/components/icons/dollar-sign-icon-new";
// import HeartIcon from "vue-material-design-icons/Heart";
export default {
  name: "Index",
  components: {
    // InfoMessage,
    SimpleDialog,
    SettingsBlock,
    ItserveLanguagePicker,
    ItserveButton,
    ItserveInput,
    VanForm: Form,
    // CheckIcon,
    UserIcon,
    // CreditCardIcon,
    // CheckBoxIOS,
    // DeveloperBoardIcon,
    ReceiptIcon,
    // HeartIcon,
    DollarSignIconNew
  },
  computed: {
    settings() {
      return this.$store.state.Settings.settings;
    },
    surname: {
      get() {
        return this.$store.state.Settings.settings.surname;
      },
      set(surname) {
        if (surname) {
          surname = surname.charAt(0).toUpperCase() + surname.slice(1);
        }
        this.$store.commit("Settings/UPDATE_SURNAME", surname);
      },
    },
    name: {
      get() {
        return this.$store.state.Settings.settings.name;
      },
      set(name) {
        if (name) {
          name = name.charAt(0).toUpperCase() + name.slice(1);
        }
        console.log("SET NAME");
        this.$store.commit("Settings/UPDATE_NAME", name);
      },
    },
    language: {
      get() {
        return this.$store.state.Auth.locale;
      },
      set(language) {
        this.$store.commit("Auth/SET_LOCALE", language);
        this.$store.dispatch("Settings/putLanguage", language);
      },
    },
    email: {
      get() {
        return this.$store.state.Settings.settings.email;
      },
      set(email) {
        this.$store.commit("Settings/UPDATE_EMAIL", email);
      },
    },
    theme: {
      get() {
        return this.$store.state.Auth.appTheme;
      },
    },
    themeReady: {
      get() {
        return this.$store.state.Auth.themeReady;
      },
    },
  },
  created() {
    this.getPageData();
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    changeSettings() {
      this.$store.dispatch("Settings/storeSettings").then(async (res) => {
        if (res) {
          this.showDialog = true;
        }
      });
    },
    async getPageData() {
      await this.$store.dispatch("Settings/getSettings");
      await this.$store.dispatch("Auth/getSubscriptionStatus");
    },
  },
};
</script>
<style>
.settings-message .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
.border {
  border-radius: 15px 15px 15px 15px;
}

.theme_checkbox {
  background-color: #fff;
  width: 100%;
  color: #0b0b0b;
  border-bottom: 1px solid #918dab;
  font-family: Arial, serif !important;
}
</style>
