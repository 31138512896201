<template>
  <header>
    <van-row>
      <van-col span="3" class="itserve-right top-menu-item">
        <template v-if="this.$route.name !== 'Home'">
          <BackButton />
        </template>
        <template v-else>
          <span class="top-bar-item">
            <account
              class="itserve-menu-icon"
              :fill-color="appTheme === 'dark' ? '#0562ad' : '#ffffff'"
            ></account>
          </span>
        </template>
      </van-col>
      <van-col span="15" class="itserve-left page-title-block">
        <template v-if="this.$route.name">
          <h1 class="page-title" @click="goBack">
            {{ $t("routes." + this.$route.name) }}
          </h1>
        </template>
      </van-col>
      <van-col span="3" class="itserve-left top-menu-item top-bar-icon-bell">
        <router-link :to="{ name: 'Notifications' }" class="top-bar-item">
          <template v-if="notificationsCount > 0">
            <van-badge
              :content="notificationsCount > 99 ? '99+' : notificationsCount"
              class="notifications-badge"
            >
              <bell
                :fill-color="appTheme === 'dark' ? '#fbc02d' : '#ffffff'"
                class="itserve-menu-icon"
              />
            </van-badge>
          </template>
          <template v-else>
            <bell
              :fill-color="appTheme === 'dark' ? '#fbc02d' : '#ffffff'"
              class="itserve-menu-icon"
            />
          </template>
        </router-link>
      </van-col>
      <!-- <van-col class="itserve-left top-menu-item" span="3">
        <router-link :to="{ name: 'FAQ' }" class="top-bar-item">
          <help-circle-icon size="26" class="itserve-menu-icon" />
        </router-link>
      </van-col> -->
      <van-col class="itserve-left top-menu-item" span="3">
        <router-link :to="{ name: 'Settings' }" class="top-bar-item">
          <cog
            class="itserve-menu-icon blue-icon-color"
            :fill-color="appTheme === 'dark' ? '#0562ad' : '#ffffff'"
          />
        </router-link>
      </van-col>
    </van-row>
  </header>
</template>
<script>
import BackButton from "@/components/template/BackButton";
import goBack from "@/mixins/go-back";
import Badge from "vant/lib/badge/index";
import Bell from "vue-material-design-icons/Bell";
import Cog from "vue-material-design-icons/Cog";
import Account from "vue-material-design-icons/Account";

export default {
  mixins: [goBack],
  comments: {},
  name: "AppHeader",
  computed: {
    notificationsCount() {
      return this.$store.state.Notifications.unreadNotifications;
    },
    routingHistory() {
      return this.$store.state.BackButton.routingHistory;
    },
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
  },
  created() {
    this.$store.dispatch("Notifications/getCountOfUnreadNotifications");
  },
  components: {
    BackButton,
    VanBadge: Badge,
    Cog,
    Bell,
    Account,
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/badge/index.css";
.top-bar-item {
  position: relative;
  top: 7px;
}
</style>
