<template>
  <van-row justify="center" class="page-wrapper setting-main-page">
    <van-col span="24" class="itserve-center flex-display-fix">
      <template v-if="settings !== null">
        <div v-if="status_sub === 0">
          <div v-if="settings.email && stripeSubscriptionData.email">

            <itserve-button
                :value="$t('stripe.subscribeButton')"
                type="primary"
                @click="subscribe"
                class="mt-15 apply-loader"
            ></itserve-button>
          </div>
          <div v-else>
            <settings-block>
              <template v-slot:icon>
                <email-icon />
              </template>
              <template v-slot:title> {{ $t("settings.email") }}</template>
              <template v-slot:content>
                Need to add email first</template
              >
            </settings-block>
            <van-form>
              <itserve-input
                  v-model="email"
                  type="email"
                  class="apply-loader"
                  :placeholder="$t('settings.email')"
                  name="email"
              />
            </van-form>
            <itserve-button
                type="primary"
                class="itserve-round-btn itserve-button-wide mb-25 apply-loader"
                :value="$t('buttons.saveChanges')"
                @click="changeSettings"
            ></itserve-button>
          </div>

        </div>
        <div v-else>
          <div class="place-content-center">
            <info-message class="apply-loader verification-message" v-if="subscription">
              {{ $t("settings.subscription_end") }}  {{ formatDate(new Date(subscription.current_period_start)) }}
            </info-message>
          </div>
          <div>
            <itserve-button
                :value="$t('stripe.unButton')"
                type="primary"
                @click="cancel"
                class="mt-15 apply-loader"
            ></itserve-button>
          </div>
        </div>
      </template>
    </van-col>
  </van-row>
</template>

<script>
import Form from "vant/lib/form/index";
import ItserveButton from "@/components/forms/ItserveButton";
import SettingsBlock from "@/components/content/SettingsBlock";
import EmailIcon from "vue-material-design-icons/Email";
import ItserveInput from "@/components/forms/ItserveInput";
import InfoMessage from "@/components/content/InfoMessage";
import Dialog from "vant/lib/dialog/index";

export default {
  components: {
    ItserveButton,
    SettingsBlock,
    EmailIcon,
    VanForm: Form,
    ItserveInput,
    InfoMessage
  },
  computed: {
    settings() {
      return this.$store.state.Settings.settings;
    },
    subscription() {
      return this.$store.state.Settings.subscription;
    },
    email: {
      get() {
        return this.$store.state.Settings.settings.email;
      },
      set(email) {
        this.$store.commit("Settings/UPDATE_EMAIL", email);
      },
    },
    status_sub: {
      get() {
        return this.$store.state.Auth.status;
      },
      set(status) {
        this.$store.commit("Auth/SET_USER_STATUS", status);
      },
    },
  },
  created() {
    this.getPageData();

  },
  data () {
    return {
      stripeSubscriptionData: {
        loading: false,
        mode: 'subscription',
        email: this.$store.state.Settings.settings.email,
        user_id: this.$store.state.Auth.user_id
      },
    };
  },
  methods: {
    changeSettings() {
      this.stripeSubscriptionData.email = this.email;
      this.$store.dispatch("Settings/storeSettings").then(async (res) => {
        if (res) {
          this.showDialog = true;
        }
      });
    },
    async getPageData() {
      await this.$store.dispatch("Settings/getSettings");
      await this.$store.dispatch("Auth/getSubscriptionStatus");
    },
    formatDate(date) {
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return `${day < 10 ? "0" + day : day}.${
          month < 10 ? "0" + month : month
      }.${date.getFullYear()}`;
    },
    async subscribe () {
      let url = await this.$store.dispatch("Payment/getStripeUrl", this.stripeSubscriptionData);
      this.nativeSendEvent("url", url);
      this.$store.commit("Auth/SET_USER_STATUS", 1);
      this.$store.commit("Auth/SET_USER_STATUS_SUB", 1);
      await this.$router.push({ name: "Settings" });
    },
    async cancel() {
      Dialog.confirm({
        title: this.$t("stripe.dialogPayMessage"),
        message: this.$t("stripe.dialogPayQuestion"),
        cancelButtonText: this.$t("stripe.dialogCancel"),
        confirmButtonText: this.$t("stripe.dialogConfirm"),
      })
          .then(async () => {
            await this.$store.dispatch("Payment/cancelSubscription", this.stripeSubscriptionData);
            this.$store.commit("Auth/SET_USER_STATUS", 0);
            this.$store.commit("Auth/SET_USER_STATUS_SUB", 0);

            await this.$router.push({ name: "Settings" });
          })
          .catch(() => {});
    }
  },
};
</script>

<style>
.verification-message .info-content {
  text-align: center !important;
}
</style>
