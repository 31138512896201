<template>
  <footer>
    <template v-if="supportRoutes.includes(currentRouteName)">
      <support-bottom-menu />
    </template>
    <template v-else>
      <!--      <template v-if="currentRouteName !== 'PayAll' && isSupportTime">-->
      <!--        <div v-if="supportsOnline" @click="openChat" class="support-helper-btn">-->
      <!--          <span class="support-text">{{ $t("components.footer.techSupport") }}</span>-->
      <!--        </div>-->
      <!--      </template>-->
      <!-- Кнопка и иконка для вызова поддержки, если чат недоступен -->
      <template v-if="!supportRoutes.includes(currentRouteName)">
        <div @click="showSupport = true" class="support-helper-btn">
          <span class="support-text">{{ $t("components.footer.techSupport") }}</span>
        </div>
        <div class="icon-box apply-loader" @click="showSupport = true">
          <chat-icon class="chat-icon" fill-color="black" size="40" />
        </div>
        <dialogFlow v-if="showSupport" @close="showSupport = false"></dialogFlow>
      </template>
    </template>
  </footer>
</template>

<script>
import SupportBottomMenu from "@/components/support/SupportBottomMenu";
import appBridge from "@/mixins/app-bridge";
// import Vue from "vue";
import store from "@/store";
// import Tawk from "@/plugins/tawk";
import ChatIcon from "vue-material-design-icons/Chat";
import DialogFlow from "../dialogFlow/dialogFlow";

export default {
  mixins: [appBridge],
  name: "AppFooter",
  data() {
    return {
      currentTime: 0,
      supportsOnline: true,
      dayNumber: 0,
      supportRoutes: ["Contacts", "WriteTicket", "SupportPage"],
      showSupport: false,
    };
  },
  components: {
    DialogFlow,
    SupportBottomMenu,
    ChatIcon,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    // isSupportTime() {
    //   return (
    //       this.currentTime >= 9 &&
    //       this.currentTime < 17 &&
    //       this.dayNumber !== 0 &&
    //       this.dayNumber !== 6
    //   );
    // },
  },
  created() {
    // this.calcTime();
    // if (this.isSupportTime) {
    //   Vue.use(Tawk, {
    //     tawkSrc: "https://embed.tawk.to/63e4e0834742512879126107/1gor0q0o1",
    //   });
    //   if (window.Tawk_API) {
    //     window.Tawk_API.onLoad = () => {
    //       store.dispatch("Auth/setUserPhone");
    //       if (window.Tawk_API.getStatus() === "offline") {
    //         this.supportsOnline = false;
    //       }
    //     };
    //   }
    // }
  },
  methods: {
    calcTime() {
      let nd = new Date();
      let options = { timeZone: 'Europe/Kyiv', hour: '2-digit', hour12: false };
      this.currentTime = new Intl.DateTimeFormat('en-US', options).format(nd);
      this.dayNumber = nd.getDay();
    },
    async openChat() {
      try {
        await store.dispatch("Auth/setUserPhone");
        if (window.Tawk_API) {
          window.Tawk_API.maximize();
        } else {
          this.nativeSendEvent("tawkSupport");
        }
      } catch (e) {
        this.nativeSendEvent("tawkSupport");
      }
    },
  },
};
</script>

<style scoped>
footer {
  background-color: #fff;
}
.support-helper-btn {
  position: relative;
  height: 75px;
  display: inline-block;
  line-height: 75px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
  border-radius: 5px;
}

.icon-box {
  background-color: #fbc02d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: fixed;
  right: 4%;
  bottom: 2%;
}

.chat-icon {
  display: block;
}

.support-text {
  color: #1d1d1d;
}
</style>
