<template>
  <van-overlay :show="show">
    <div class="wrapper">
      <div class="block">

        <van-loading type="spinner" vertical >
          <template v-if="loadingMessage === true">
            <van-row>
              <van-col span="4"></van-col>
              <van-col span="16" style="text-align: center">
                {{
                  $t("pay.loadingText")
                }}
              </van-col>
              <van-col span="4"></van-col>
            </van-row>
          </template>
        </van-loading>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import Overlay from "vant/lib/overlay/index";
import Loading from "vant/lib/loading/index";
export default {
  components: { VanOverlay: Overlay, VanLoading: Loading },
  props: {
    show: Boolean,
    message: Boolean
  },
  name: "Loader",
  computed: {
    loadingMessage() {
      return this.$store.state.Api.loadingMessage;
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/loading/index.css";
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.itserve-info-message-new {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0b0b0b;
  font-size: 18px;
  text-align: center;
  max-width: 337px;
}
.app-theme-dark .itserve-info-message-new{
  color: #ffffff;
}
.info-content-new {
  max-width: 317px;
  display: inline-block;
  text-align: center;
  padding: 10px;
}
</style>
